import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// import MainNav from "./mainNav"
import ThemeButton from "./themeButton"

const Header = ({ siteTitle }) => (
  <header className="wrapper">
    <h1>
      <Link
        to="/"
        className="header"
      >
        {siteTitle}
      </Link>
    </h1>
    <button className="skip-to-content no-border">Skip to content</button>
    <div className="header">
      <ThemeButton />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
