/**
 * Creates a button to toggle Dark/Light themes.
 */
import React, { useState, useEffect } from "react"
import { HiOutlineSun, HiOutlineMoon } from "react-icons/hi"



const ThemeButton = () => {

  const [mode, setMode] = useState(typeof window !== 'undefined' ? window.__theme : null );
  //console.log('init mode ',mode);
  let icon = (typeof window !== 'undefined' ?  (mode === 'light' ? <HiOutlineMoon /> : <HiOutlineSun />) : null );
  //console.log(icon);

  function setTheme(t) {
    // console.log('setting icon   ', t)
    // console.log(window.__btnClicked)
    // console.log('---------')
    window.__theme = t;
    document.documentElement.className = t;
    if(t === 'light') {
      icon = <HiOutlineMoon />
    } else {
      icon = <HiOutlineSun />
    }
    if(window.__btnClicked) {
      window.localStorage.setItem('theme', t)
    }
  }

  function toggle(){
    window.__btnClicked = true;
    //console.log(window.__btnClicked)
    if(mode === 'light') {
      setMode('dark')
    } else {
      setMode('light')
    }
    //console.log('toggle ', mode);
  }

  useEffect(() => {
    if(typeof window !== 'undefined') {
      setTheme(mode)
     }
    });

  return(
    <button className="toggle-dark-mode no-border" aria-pressed="false" aria-label="toggle theme mode" onClick={toggle}>{icon}</button>
   )

}

export default ThemeButton
