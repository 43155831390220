/**
 * Creates a button to scroll to top - displayed only after window scrolls a bit.
 */
import React, { useState, useEffect } from "react"
import { FaChevronUp } from "react-icons/fa"

const Scroll = ({ showBelow }) => {

    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    return (
        <>
            {show &&
                <a onClick={handleClick} id="toTop" aria-label="to top" title="scroll to top">
                    <FaChevronUp />
                </a>
            }
        </>
    )
}
export default Scroll
