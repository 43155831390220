import React from "react"
import {FaReact, FaWordpressSimple } from 'react-icons/fa';
import { RiGatsbyLine } from "react-icons/ri"


const Footer = () => {
  return (
    <footer>
      <div>
        <p>By <a href="https://fiveblackcats.com" title="Fiveblackcats Design, Rotterdam">Fiveblackcats</a> using <a href="https://wordpress.org" title="WordPress"><FaWordpressSimple /></a>, <a href="https://reactjs.org" title="React"><FaReact /></a> and <a href="https://www.gatsbyjs.com" title="Gatsby"><RiGatsbyLine /></a>.</p>
      </div>
    </footer>
  )
}

export default Footer
